import React, { useEffect, useState, Suspense, lazy } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
// store
import { startSse, stopSse } from './store/authSlice'
import { setCallUrl } from './store/appSlice'
// components
import Loading from './components/widgets/page/Loading'
import FloatingWindow from './components/widgets/floating-window/FloatingWindow'
// styles
import './app.scss'

// Lazy load components
const LoginPage = lazy(() => import('./components/pages/auth/LoginPage'))
const ChangePasswordPage = lazy(() => import('./components/pages/auth/ChangePasswordPage'))
const ConsultationPagePayment = lazy(() => import('./components/pages/consultation/ConsultationPagePayment'))
const AppAuthenticated = lazy(() => import('./AppAuthenticated'))
const AttendancePage = lazy(() => import('./components/pages/attendance/AttendancePage'))
const MeetingPage = lazy(() => import('./components/pages/meeting/MeetingPage'))
const PlaygroundPage = lazy(() => import('./components/pages/playground/PlaygroundPage'))
const ValidatePage = lazy(() => import('./components/pages/document/DocumentValidatePage'))
const VidaasFeedbackPage = lazy(() => import('./components/pages/digital-signature/VidaasFeedbackPage'))
const HealthProfessionalPageListPatient = lazy(() => import('./components/pages/health-professional/HealthProfessionalPageList.patient'))
const HealthProfessionalPageDetailsPatient = lazy(() => import('./components/pages/health-professional/HealthProfessionalPageDetails.patient'))
const HealthProfessionalPageSchedulePatient = lazy(() => import('./components/pages/health-professional/HealthProfessionalPageSchedule.patient'))

const ProtectedRoute = ({ element: Component, ...rest }) => {
  const auth = useSelector((state) => state.auth)
  const location = useLocation()
  const redirectUrl = `${location.pathname}${location.search}`

  return (!auth.loading && !auth.isAuthenticated) ? (
    <Navigate to={`/login?callback=${encodeURIComponent(redirectUrl)}`} />
  ) : (
    <Component {...rest} />
  )
}

function App() {

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated)
  const theme = useSelector((state) => state.app.theme)
  const callUrl = useSelector((state) => state.app.callUrl)

  const [permissionsGranted, setPermissionsGranted] = useState(false)

  useEffect(() => {
    if (callUrl) {
      // Solicita permissão para usar a câmera e o microfone
      navigator.mediaDevices.getUserMedia({ video: true, audio: true })
        .then((stream) => {
          setPermissionsGranted(true)
          // Libera o stream após obter permissão
          stream.getTracks().forEach(track => track.stop())
        })
        .catch((err) => {
          console.error('Permissão negada ou erro ao acessar a câmera/microfone:', err)
          setPermissionsGranted(false)
        })
    }
  }, [callUrl])

  const appTheme = createTheme({
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            backgroundColor: theme === 'dark' ? '#0c1212' : '#dbdbdb', // #rgba(0, 0, 0, 0.12) = #dbdbdb
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          asterisk: {
            color: '#f44336', // Cor vermelha para o asterisco
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: 'none',
            fontWeight: 'bold'
            // fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            // fontSize: '0.875rem',
          },
          outlined: {
            borderWidth: '2px', // Define a borda de 2px para botões outlined
            '&:hover': {
              borderWidth: '2px', // Mantém a borda de 2px no hover
            },
            ...(theme === 'light' ? {
              backgroundColor: 'rgba(255, 255, 255, 0.4)'
            } : {})
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          root: {
            '& .MuiTab-root': {
              textTransform: 'none', // Define a fonte como caixa baixa
            },
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            textTransform: 'none', // Define a fonte como caixa baixa
          },
        },
      },
    },
    palette: {
      mode: theme, // dark light
      ...(theme === 'dark' ? {
        // primary: { main: 'rgb(102, 157, 246)' },
        background: {
          paper: '#121a1c', //'rgb(5, 30, 52)',
          default: '#0c1212',
        },
        text: {
          divider: '#3a444b'
        },
        divider: '#3a444b',
        // Personalizadas
        tableHeader: 'rgba(255, 255, 255, 0.06)',
      } : {
        // primary: { main: '#0069ff' },
        /*background: {
          paper: '#fbfafd'
        }*/
        // Personalizadas

        tableHeader: 'rgba(0, 0, 0, 0.06)',
        body: '#dbdbdb'
      })
    },
    shape: {
      borderRadius: 4 // Defina o borderRadius do tema
    },
  })

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(startSse())
    } else {
      dispatch(stopSse())
    }

    return () => {
      dispatch(stopSse())
    }
  }, [isAuthenticated, dispatch])

  return (
    <ThemeProvider theme={appTheme}>
      <CssBaseline />
      <Router>
        <Suspense fallback={<Loading />}>
          <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/change-password" element={<ChangePasswordPage />} />
            <Route path="/meeting/:token" element={<MeetingPage />} />
            <Route path="/playground" element={<PlaygroundPage />} />
            <Route path="/attendance/:id/:page?/:sectionId?" element={<ProtectedRoute element={AttendancePage} />} />
            <Route path="/validate/:protocol?/:password?" element={<ValidatePage />} />
            <Route path="/vidaas" element={<VidaasFeedbackPage />} />
            <Route path="/public/consultation/:id/payment" element={<ConsultationPagePayment publicPage />} />
            <Route path="/public/health-professional" element={<HealthProfessionalPageListPatient publicPage />} />
            <Route path="/public/health-professional/:id" element={<HealthProfessionalPageDetailsPatient publicPage />} />
            <Route path="/public/health-professional/:id/specialty/:specialtyId" element={<HealthProfessionalPageSchedulePatient publicPage />} />
            <Route path="*" element={<ProtectedRoute element={AppAuthenticated} />} />
          </Routes>
        </Suspense>
      </Router>
      {Boolean(callUrl && permissionsGranted) &&
        <FloatingWindow
          title={t('video_chamada')}
          onClose={() => dispatch(setCallUrl(null))}
        >
          <iframe
            src={callUrl}
            allow="microphone; camera; autoplay; display-capture; screen-wake-lock"
            style={{ width: "100%", height: "100%", border: "none" }}
            title={t('video_chamada')}
          />
        </FloatingWindow>
      }
    </ThemeProvider>
  )
}

export default App
